<template>
  <v-container :style="`width: ${($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '1200px !important'};`">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <h1 class="mb-2">{{ $t("productOrder.myOrderTitle") }}</h1>
        <!-- {{ desserts }} -->
        <v-data-table
          :headers="headers"
          :items="desserts"
          class="elevation-1"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :mobile-breakpoint="0"
          :loading="isLoading"
          loading-text="Loading... Please wait"
        >
          <template
            v-for="h in headers"
            v-slot:[`header.${h.value}`]="{ header }"
          >
            {{ $t(header.text) }}
          </template>
          <template v-slot:[`item.orderCode`]="item">
            <span
              class="primary--text cursor-pointer"
              @click="onViewDetail(item.item)"
              >{{ item.item.orderCode }}</span
            >
          </template>
          <template v-slot:[`item.status`]="item">
            <v-chip :color="getColor(item.item.status)" dark>
              {{ $t(getStatus(item.item.status)) }}
            </v-chip>
          </template>
          <template v-slot:[`item.actions`]="item">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="error"
                  @click="onDelete(item.item)"
                  v-show="
                    item.item.status != productOrderStatusEnum.CANCEL &&
                      item.item.status != productOrderStatusEnum.PAID
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-close
                </v-icon>
              </template>
              <span>Huỷ đơn hàng</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </v-col>
    </v-row>
    <confirmModal
      ref="confirmModal"
      :title="confirmModalTitle"
      @confirm="onCancelOrder"
    />
    <orderDetailModal ref="orderDetailModal" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import functionUtils from "Utils/functionUtils";
import dateUtils from "Utils/dateUtils";
import ProductOrderStatusEnum from "Enum/productOrderStatusEnum";
import PromotionTypeEnum from 'Enum/promotionTypeEnum'
import VoucherProductApplyType from "Enum/voucherProductApplyType"
import ConfirmModal from "Components/ConfirmModal";
import OrderDetailModal from "./OrderDetailModal.vue";
export default {
  components: {
    ConfirmModal,
    OrderDetailModal,
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      headers: [
        { text: "#", value: "stt" },
        { text: "productOrder.tableList.header.orderCode", value: "orderCode" },
        {
          text: "productOrder.tableList.header.purchaseDate",
          value: "purchaseDate",
        },
        { text: "productOrder.tableList.header.product", value: "product" },
        {
          text: "productOrder.tableList.header.totalPrice",
          value: "totalPrice",
        },
        { text: "productOrder.tableList.header.status", value: "status" },
        { text: "paymentMethod.title", value: "paymentMethod" },
        { text: "common.actions", value: "actions" },
      ],
      desserts: [],
      confirmModalTitle: "",
      orderProductId: null,
      productOrderStatusEnum: {
        CANCEL: ProductOrderStatusEnum.CANCEL,
        PAID: ProductOrderStatusEnum.PAID,
      },
      isLoading: false
    };
  },
  created() {
    this.getProductOrder();
  },
  methods: {
    onViewDetail: function(product) {
      this.$refs.orderDetailModal.onShowModalOrderDetail(product);
    },
    /**
     * Get color
     */
    getColor(status) {
      if (status == ProductOrderStatusEnum.CANCEL) return "red";
      else if (status == ProductOrderStatusEnum.WAIT_FOR_PAY) return "orange";
      else return "green";
    },
    /**
     * Get product order
     */
    getProductOrder: function() {
      let filter = {
        params: {
          customerFilter: true,
        },
      };
      this.isLoading = true
      this.GET_PRODUCT_ORDER(filter).then(
        function(res) {
          let data = res.data;
          this.desserts = [];
          for (let i = 0, size = data.length; i < size; i++) {
            let promotionCode = data[i].promotion_code
            let promotionOrderList = promotionCode.filter(
              x => x.apply_type === VoucherProductApplyType.ORDER)
            let productList = data[i].items
            let totalPrice = this.getTotalPrice(productList)
            let totalPromotionOrderPrice = this.getPromotionPriceOrder(totalPrice, promotionOrderList)
            let finalPrice = totalPrice - totalPromotionOrderPrice
            let obj = {
              stt: i + 1,
              id: data[i].id,
              orderCode: data[i].code,
              purchaseDate: dateUtils.formatDate(
                data[i].date_created,
                dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
                dateUtils.STATIC_FORMAT_DATE_TIME
              ),
              product: "Voucher",
              totalPrice: functionUtils.convertFormatNumberToMoney(finalPrice.toString()),
              status: data[i].status,
              productsList: productList,
              deliveryInfo: data[i].delivery_info,
              paymentMethod: this.$t(functionUtils.paymentMethodTypeName(data[i].payment_method)),
              totalPromotionOrderPrice: totalPromotionOrderPrice
            };
            this.desserts.push(obj);
          }
          this.isLoading = false
        }.bind(this)
      );
    },
    /**
     * Get status
     */
    getStatus: function(status) {
      if (status == ProductOrderStatusEnum.PAID) {
        return "productOrder.tableList.status.paid";
      } else if (status == ProductOrderStatusEnum.WAIT_FOR_PAY) {
        return "productOrder.tableList.status.waitForPay";
      } else if (status == ProductOrderStatusEnum.CANCEL) {
        return "productOrder.tableList.status.cancel";
      }
    },
    /**
     * Get total price
     */
    getTotalPrice: function(items) {
      let total = 0;
      for (let i = 0, size = items.length; i < size; i++) {
        let price = !functionUtils.validateStringIsNull(items[i].price) ?
          items[i].price : items[i].workpiece.value
        let totalVoucherPrice = price * items[i].quantity
        let promotionValue = items[i].product_promotion && items[i].product_promotion.value
        let promotionType = items[i].product_promotion && items[i].product_promotion.promotion_type
        let promotionPrice = this.getPromotionPriceVoucher(price, promotionValue, promotionType)
        total += totalVoucherPrice - promotionPrice;
      }
      total = total > 0 ? total : 0
      return total;
    },
    /**
     * Edit
     */
    onEdit: function() {
      // Todo
    },
    /**
     * Delete
     */
    onDelete: function(item) {
      this.orderProductId = item.id;
      this.confirmModalTitle = this.$t("confirmModal.confirmCancelOrder");
      this.$refs.confirmModal.onShowModal();
    },
    /**
     * Cancel order
     */
    onCancelOrder: function() {
      let filter = {
        id: this.orderProductId,
      };
      this.CANCEL_VOUCHER_PRODUCT_ORDER(filter).then(
        function() {
          this.getProductOrder();
        }.bind(this)
      );
    },
    getPromotionPriceVoucher: function(voucherPrice, promotionValue, promotionType) {
      if (promotionType === PromotionTypeEnum.PRICE) {
        return promotionValue
      } else if (promotionType === PromotionTypeEnum.PERCENT) {
        return voucherPrice * promotionValue / 100
      }
      return 0
    },
    getPromotionPriceOrder: function(voucherTotalPrice, promotionOrderList) {
      let result = 0
      promotionOrderList.forEach(e => {
        if (e.promotion_type === PromotionTypeEnum.PRICE) {
          result += e.value
        } else if (e.promotion_type === PromotionTypeEnum.PERCENT) {
          result += voucherTotalPrice * e.value / 100
        }
      })
      return result
    },
    ...mapActions(["GET_PRODUCT_ORDER", "CANCEL_VOUCHER_PRODUCT_ORDER"]),
  },
};
</script>

<style lang="scss" scoped>
.no-space {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
