<template>
  <div>
    <v-dialog
      v-model="dialog"
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
      width="1000"
    >
      <v-card tile>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            $t("productOrder.detail.order") +
              " " +
              orderCode +
              " " +
              $t("productOrder.detail.detail")
          }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form v-if="fromAdmin">
            <v-container>
              <p class="text-left font-weight-bold">{{ $t('productOrder.infoAboutCustomersOrderingVouchers') }}</p>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="userOrder.fullname" disabled :label="$t('common.customer')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="userOrder.email" disabled :label="$t('common.email')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="userOrder.phone" disabled :label="$t('profile.phone')"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-if="deliveryInfo" class="mt-0">
              <p class="text-left font-weight-bold">{{ $t('productOrder.voucherRecipientInfo') }}</p>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="deliveryInfo.fullname" disabled :label="$t('common.customer')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="deliveryInfo.email" disabled :label="$t('common.email')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="deliveryInfo.phone" disabled :label="$t('profile.phone')"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-form v-else-if="deliveryInfo" class="mt-4">
            <p class="text-left font-weight-bold">{{ $t('productOrder.voucherRecipientInfo') }}</p>
            <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="deliveryInfo.fullname" disabled :label="$t('common.customer')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="deliveryInfo.email" disabled :label="$t('common.email')"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-model="deliveryInfo.phone" disabled :label="$t('profile.phone')"></v-text-field>
                </v-col>
              </v-row>
          </v-form>
          <v-data-table
            :headers="productHeaders"
            :items="productsList"
            class="mt-6 elevation-1"
            hide-default-footer
            :mobile-breakpoint="0"
          >
            <template
              v-for="h in productHeaders"
              v-slot:[`header.${h.value}`]="{ header }"
            >
              {{ $t(header.text) }}
            </template>
            <template v-slot:[`item.image`]="item">
              <img
                :src="item.item.image"
                class="pt-2"
                width="100px"
                height="70px"
              />
            </template>
            <template v-slot:[`item.quantity`]="item">
              {{ "x" + item.item.quantity }}
            </template>
            <template v-slot:[`item.price`]="item">
              {{ formatMoney(item.item.price) + " VND" }}
            </template>
            <template v-slot:[`item.totalPrice`]="item">
              {{ formatMoney(item.item.totalPrice) + " VND" }}
            </template>
            <template v-slot:[`item.promotionValue`]="item">
              <span v-if="item.item.promotionType === promotionTypeEnum.PRICE">
                {{ item.item.promotionValue ? formatMoney(item.item.promotionValue) + " VND" : null }}
              </span>
              <span v-else>
                {{ item.item.promotionValue ? item.item.promotionValue + "%" : null }}
              </span>
            </template>
            <template v-slot:[`item.promotionCode`]="item">
              {{ item.item.promotionCode }}
            </template>
            <template v-slot:footer>
              <div class="py-2 table-responsive">
                <table class="table m-0">
                  <tbody>
                    <tr>
                      <td style="width: 100%"></td>
                      <td style="white-space: nowrap;" class="text-right">
                        {{ $t('productOrder.tableList.header.totalPrice') }}
                      </td>
                      <td
                        class="footer-cell-price text-right">{{formatMoney(totalPrice) + " VND"}}</td>
                    </tr>
                    <tr>
                      <td style="width: 100%"></td>
                      <td style="white-space: nowrap;" class="text-right">
                        {{ $t('paymentMethod.discountOnBill') }}
                      </td>
                      <td
                        class="footer-cell-price text-right">{{formatMoney(totalPromotionOrderPrice) + " VND"}}</td>
                    </tr>
                    <tr>
                      <td style="width: 100%"></td>
                      <td style="white-space: nowrap;" class="text-right">
                        {{ $t('productOrder.promotion.totalPriceToPay') }}
                      </td>
                      <td class="footer-cell-price text-right">{{finalTotalPrice + " VND"}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import functionUtils from "Utils/functionUtils";
import PromotionTypeEnum from 'Enum/promotionTypeEnum'

export default {
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    productHeaders: [
      { text: "#", value: "stt" },
      { text: "productOrder.detail.image", value: "image" },
      { text: "productOrder.tableList.header.product", value: "product" },
      {
        text: "productOrder.detail.price",
        value: "price",
        cellClass: 'text-end'
      },
      { text: "productOrder.detail.quantity", value: "quantity", cellClass: 'text-end' },
      { text: "productOrder.promotion.code", value: "promotionCode", cellClass: 'text-end' },
      { text: "productOrder.promotion.discount", value: "promotionValue", cellClass: 'text-end' },
      { text: "productOrder.tableList.header.totalPrice", value: "totalPrice", cellClass: 'text-end' },
    ],
    productsList: [],
    orderCode: null,
    fromAdmin: false,
    userOrder: null,
    deliveryInfo: null,
    promotionTypeEnum: PromotionTypeEnum,
    totalPrice: 0,
    totalPromotionOrderPrice: 0,
    finalTotalPrice: 0
  }),
  methods: {
    onShowModalOrderDetail: function(product, fromAdmin) {
      this.deliveryInfo = null
      this.fromAdmin = fromAdmin;
      let data = product.productsList;
      this.orderCode = product.orderCode;
      this.totalPrice = 0
      if (fromAdmin) {
        this.userOrder = product.userOrder
        this.userOrder['fullname'] = functionUtils.concatFullname(this.userOrder.last_name, this.userOrder.first_name)
      }
      this.deliveryInfo = product.deliveryInfo
      this.productsList = [];
      for (let i = 0, size = data.length; i < size; i++) {
        let price = !functionUtils.validateStringIsNull(data[i].price) ?
          data[i].price : data[i].workpiece.value
        let totalVoucherPrice = price * data[i].quantity
        let promotionValue = data[i].product_promotion && data[i].product_promotion.value
        let promotionType = data[i].product_promotion && data[i].product_promotion.promotion_type
        let promotionPrice = this.getPromotionPriceVoucher(price, promotionValue, promotionType)
        let promotionCode = data[i].product_promotion && data[i].product_promotion.code
        this.totalPrice += totalVoucherPrice - promotionPrice
        let totalPrice = (price * data[i].quantity) - promotionPrice
        totalPrice = totalPrice > 0 ? totalPrice : 0
        let obj = {
          stt: i + 1,
          product: data[i].workpiece.name,
          price: price,
          quantity: data[i].quantity,
          totalPrice: totalPrice,
          image: data[i].workpiece.image_url,
          promotionValue: promotionValue,
          promotionType: promotionType,
          promotionCode: promotionCode
        };
        this.productsList.push(obj);
      }
      this.totalPrice = this.totalPrice > 0 ? this.totalPrice : 0
      this.totalPromotionOrderPrice = product.totalPromotionOrderPrice
      this.finalTotalPrice = product.totalPrice
      this.dialog = true;
    },
    formatMoney: function(price) {
      return functionUtils.convertFormatNumberToMoney(price.toString());
    },
    getPromotionPriceVoucher: function(voucherPrice, promotionValue, promotionType) {
      if (promotionType === PromotionTypeEnum.PRICE) {
        return promotionValue
      } else if (promotionType === PromotionTypeEnum.PERCENT) {
        return voucherPrice * promotionValue / 100
      }
      return 0
    }
  },
};
</script>

<style lang="scss" scoped>
// IE11 Data table footer fix
.v-data-footer__select {
  flex-basis: auto;

  .v-select {
    flex-basis: 50px;

    .v-select__selections {
      flex-basis: auto;
    }
  }
}

.v-list-item__content {
  flex: 1 1 auto;
  min-width: 40px;
}
.footer-cell-price {
  width: fit-content;
  white-space: nowrap;
  padding-left: 16px;
  padding-right: 16px
}
</style>
