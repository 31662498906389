var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{style:(("width: " + ((_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm) ? '' : '1200px !important') + ";"))},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('h1',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t("productOrder.myOrderTitle")))]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"page":_vm.page,"hide-default-footer":"","mobile-breakpoint":0,"loading":_vm.isLoading,"loading-text":"Loading... Please wait"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(_vm.$t(header.text))+" ")]}}}),{key:"item.orderCode",fn:function(item){return [_c('span',{staticClass:"primary--text cursor-pointer",on:{"click":function($event){return _vm.onViewDetail(item.item)}}},[_vm._v(_vm._s(item.item.orderCode))])]}},{key:"item.status",fn:function(item){return [_c('v-chip',{attrs:{"color":_vm.getColor(item.item.status),"dark":""}},[_vm._v(" "+_vm._s(_vm.$t(_vm.getStatus(item.item.status)))+" ")])]}},{key:"item.actions",fn:function(item){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(
                  item.item.status != _vm.productOrderStatusEnum.CANCEL &&
                    item.item.status != _vm.productOrderStatusEnum.PAID
                ),expression:"\n                  item.item.status != productOrderStatusEnum.CANCEL &&\n                    item.item.status != productOrderStatusEnum.PAID\n                "}],attrs:{"medium":"","color":"error"},on:{"click":function($event){return _vm.onDelete(item.item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")])]}}],null,true)},[_c('span',[_vm._v("Huỷ đơn hàng")])])]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1),_c('confirmModal',{ref:"confirmModal",attrs:{"title":_vm.confirmModalTitle},on:{"confirm":_vm.onCancelOrder}}),_c('orderDetailModal',{ref:"orderDetailModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }